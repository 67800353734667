import _ from "lodash";
import { TableReport, JidoUtils } from "./kraftheinz-jido-seisei.es.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import { JIDO_SEISEI_FILE_STORAGE } from "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "w-100 h-100", staticStyle: { "background": "white" } }, [_c("table-report", { attrs: { "is-tbl-folder": true, "is-fetch": _vm.isFetchingMonth, "is-downloading": _vm.isDownloading, "time-selected": _vm.selectedTime, "data-table": _vm.dataTable, "row-keys": _vm.tableHeader, "pagination": _vm.pagination }, on: { "click-row": _vm.clickRow, "on-download": _vm.onDownload, "on-download-all": _vm.onDownloadAll, "on-table-change": _vm.onTableChange, "search-by-word": _vm.onSearchByWord } })], 1);
};
var staticRenderFns$1 = [];
var ReportFolders_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "ReportFolders",
  components: { TableReport },
  mixins: [JidoUtils],
  inject: ["resourceName", "crud"],
  data() {
    const timeSelected = this.$route.query;
    return {
      apiUrl: apiUrl$1,
      selectedTime: timeSelected || null,
      isFetching: false,
      isMounted: false,
      isDownloading: false,
      typeOfFile: "",
      tableHeader: [
        {
          key: "saleName",
          title: "\u30D5\u30A1\u30A4\u30EB\u540D",
          hasIcon: true,
          type: "text"
        }
      ]
    };
  },
  computed: {
    dataTable() {
      const data = this.crud.getList();
      if (!data.length)
        return null;
      return data;
    },
    isFetchingMonth() {
      if (this.$store.state["jidoseisei.common.list-month-of-year"].isFetchingList)
        return true;
      else {
        if (this.isFetching)
          return true;
      }
      return false;
    },
    pagination() {
      const { page, count, pageSize } = this.crud.getPagination();
      return {
        current: page,
        showSizeChanger: true,
        total: count,
        pageSize
      };
    }
  },
  watch: {
    async $route(to, from) {
      if (to.query !== from.query && this.isMounted) {
        this.isFetching = true;
        this.selectedTime = to.query;
        this.crud.setPagination({ page: 1 });
        await this.fetchList();
      }
    }
  },
  async mounted() {
    this.isFetching = true;
    await this.fetchList();
    this.isMounted = true;
  },
  methods: {
    async fetchListFolder() {
      const { page, pageSize } = this.crud.getPagination();
      this.crud.deleteFilter("IsInactive");
      this.crud.deletePagination();
      this.crud.setFilter("Month", {
        operator: "Eq",
        value: this.selectedTime.month
      });
      this.crud.setFilter("Year", {
        operator: "Eq",
        value: this.selectedTime.year
      });
      this.crud.setPagination({ page, pageSize });
      await this.crud.fetchList();
    },
    async fetchList(isSearching = false, value = "") {
      if (this.selectedTime.month) {
        if (!isSearching) {
          this.crud.deleteFilter("SaleName");
          await this.fetchListFolder();
          this.isFetching = false;
          return;
        }
        this.crud.setFilter("SaleName", {
          operator: "Contains",
          value
        });
        await this.fetchListFolder();
      }
    },
    onSearchByWord: _.debounce(async function(e) {
      await this.fetchList(true, e.target.value);
    }, 400),
    clickRow(val) {
      this.$router.push({
        path: `/jido-seisei/payment-statement-creation/detail-report/${val.id}/dafiles/${val.saleName}`,
        query: this.selectedTime
      });
    },
    async onDownload(selectedFile) {
      this.isDownloading = true;
      this.typeOfFile = selectedFile.type.toString();
      const params = {
        Year: {
          operator: "Eq",
          value: this.selectedTime.year
        },
        Month: {
          operator: "Eq",
          value: this.selectedTime.month
        },
        SaleNameId: {
          operator: "In",
          value: selectedFile.record.id
        }
      };
      try {
        await this.handleDownload(this.typeOfFile, params);
        this.isDownloading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async onDownloadAll(selectedFile) {
      this.isDownloading = true;
      this.typeOfFile = selectedFile.type.toString();
      const params = {
        Year: {
          operator: "Eq",
          value: this.selectedTime.year
        },
        Month: {
          operator: "Eq",
          value: this.selectedTime.month
        }
      };
      try {
        await this.handleDownload(this.typeOfFile, params);
        this.isDownloading = false;
      } catch (err) {
        console.log(err);
      }
    },
    onTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.crud.setPagination({
        ...this.crud.getPagination(),
        page: current,
        pageSize
      });
      this.fetchListFolder();
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ReportFolders = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "w-100 h-100", staticStyle: { "background": "white" } }, [_c("resource", { attrs: { "name": "jidoseisei.saleman", "api-url": _vm.apiUrl, "page": _vm.page } }, [_c("report-folders")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { ReportFolders },
  data() {
    return {
      apiUrl,
      page: JIDO_SEISEI_FILE_STORAGE
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
